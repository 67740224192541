<template>
  <div>
    <div class="full-height">
      <v-row justify="center" style="height: 90%">
        <v-col cols="11">
          <div v-if="!insuranceLoading" class="d-flex justify-end mb-3">
            <v-btn
              color="primary"
              class="pa-1"
              text
              @click="addInsuranceData()"
            >
              <v-icon>add</v-icon>
              Add Insurance
            </v-btn>
          </div>
          <!-- <v-card class="pa-1 my-4" width="100%" raised height="100%"> -->
          <v-form
            v-for="(ins, index) in addInsurance"
            :key="index"
            :ref="'insForm' + index"
          >
            <div
              v-if="addInsurance.length > 1 || ins.insuranceId"
              class="d-flex justify-end mb-3"
            >
              <v-btn
                color="primary lighten-1"
                text
                @click="removeInsuranceData(index, ins)"
              >
                <v-icon>delete</v-icon>
                Remove
              </v-btn>
            </div>
            <ValidationObserver :ref="'insuranceForm' + index">
              <v-row>
                <v-col cols="12">
                  <v-card flat class="pa-6">
                    <v-row class="ma-4">
                      <v-col cols="12" sm="6" lg="6" md="6" xl="6">
                        <ValidationProvider
                          ref="policyNumberValidationProvider"
                          v-slot="{ errors }"
                          name="Policy Number"
                          rules="required|min:3|max:30"
                        >
                          <v-text-field
                            :id="'Policy Number'"
                            v-model="ins.policyNumber"
                            outlined
                            label="Policy Number*"
                            :counter="30"
                            :maxlength="30"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6" lg="6" md="6" xl="6">
                        <ValidationProvider
                          ref="insuranceNameValidationProvider"
                          v-slot="{ errors }"
                          name="Insurance Name"
                          rules="required|alphaSpaceDot|max:30|min:3"
                        >
                          <v-text-field
                            :id="'Insurance_Name'"
                            v-model="ins.nameOfInsurance"
                            outlined
                            :counter="300"
                            :maxlength="300"
                            label="Name of Insurance*"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6" lg="6" md="6" xl="6">
                        <ValidationProvider
                          ref="insurerNameValidationProvider"
                          v-slot="{ errors }"
                          name="Insurer Name"
                          rules="required|alphaSpaceDot|max:300|min:3"
                        >
                          <v-text-field
                            :id="'Insurer_Name'"
                            v-model="ins.nameOfInsurer"
                            outlined
                            :counter="300"
                            :maxlength="300"
                            label="Name of Insurer*"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6" lg="6" md="6" xl="6">
                        <ValidationProvider
                          ref="insuredNameValidationProvider"
                          v-slot="{ errors }"
                          name="Insured Name"
                          rules="required|alphaSpaceDot|max:300|min:3"
                        >
                          <v-text-field
                            :id="'Insured_Name'"
                            v-model="ins.nameOfInsured"
                            outlined
                            :counter="300"
                            :maxlength="300"
                            label="Name of Insured*"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col cols="12" sm="6" lg="6" md="6" xl="6">
                        <ValidationProvider
                          ref="sumOfInsuredValidationProvider"
                          v-slot="{ errors }"
                          name="Sum Insured"
                          rules="required|numeric|minimum:1|maximum:100000000"
                        >
                          <v-text-field
                            :id="'Sum Insured'"
                            v-model="ins.sumOfInsured"
                            outlined
                            label="Sum Insured*"
                            type="number"
                            :error-messages="errors"
                            @input="
                              ins.sumOfInsured = parseInt(ins.sumOfInsured)
                            "
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col cols="12" sm="6" lg="6" md="6" xl="6">
                        <ValidationProvider>
                          <v-dialog v-model="expireModal" width="290">
                            <template #activator="{ on }">
                              <ValidationProvider
                                ref="expireDateValidationProvider"
                                v-slot="{ errors }"
                                name="Expiry Date"
                                rules="required"
                              >
                                <v-text-field
                                  :id="'ExpiryDate'"
                                  outlined
                                  :value="formatDate(ins.expireDate)"
                                  label="Expiry Date of Insurance*"
                                  v-on="on"
                                  :error-messages="errors"
                                ></v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              v-model="ins.expireDate"
                              @change="expireModal = false"
                            ></v-date-picker>
                          </v-dialog>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" class="mb-2">
                        <DragAndDropFiles
                          :id="'otherInsurance' + index"
                          file-category="insurance"
                          :old-file-name="ins.fileName"
                          :key-index="'otherInsurance-File-Upload' + index"
                          @file-event-success="updateFileName($event, index)"
                          @file-event-triggered="$emit('file-event-triggered')"
                        ></DragAndDropFiles>
                      </v-col>
                      <v-col cols="12">
                        <ValidationProvider
                          ref="descriptionValidator"
                          v-slot="{ errors }"
                          name="Description"
                          rules="descriptionValidation|max:1000|min:2"
                        >
                          <v-textarea
                            :id="'Description'"
                            v-model="ins.description"
                            outlined
                            auto-grow
                            :counter="1000"
                            :maxlength="1000"
                            label="Specify any exclusions and deductibles to the above Insurance Policy."
                            :error-messages="errors"
                          ></v-textarea>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </ValidationObserver>
          </v-form>
          <v-overlay
            absolute
            :value="insuranceLoading || saveLoading"
            color="#fff"
            z-index="1"
          >
            <v-progress-circular
              color="primary"
              indeterminate
              size="50"
            ></v-progress-circular>
          </v-overlay>
          <!-- </v-card> -->
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import DragAndDropFiles from "@/components/custom-components/DragAndDropFiles.vue";
import moment from "moment";
import { DELETE_INSURANCE_DETAILS } from "@/graphql/vendorOnboardQueries";

export default {
  name: "SkillProfiles",

  components: { DragAndDropFiles },

  props: {
    insuranceData: {
      type: Array,
      required: false,
    },
  },

  data: () => ({
    addInsurance: [],
    expireModal: false,
    saveLoading: false,
    insuranceLoading: false,
  }),

  mounted() {
    this.addInsurance = [];
    if (this.insuranceData && this.insuranceData.length) {
      for (let insurance of this.insuranceData) {
        this.addInsurance.push({
          insuranceId: insurance.insuranceId,
          policyNumber: insurance.policyNumber,
          nameOfInsurer: insurance.nameOfInsurer,
          nameOfInsured: insurance.nameOfInsured,
          nameOfInsurance: insurance.nameOfInsurance,
          expireDate: insurance.expiryDate,
          description: insurance.description,
          fileNameWithPath: insurance.documentFile,
          typeOfInsurance: insurance.typeOfInsurance,
          sumOfInsured: insurance.sumInsured ? insurance.sumInsured : 0,
          fileName: insurance.documentFile
            ? insurance.documentFile.split("?")[3]
            : "",
        });
      }
    } else {
      this.addInsurance.push({
        insuranceId: 0,
        policyNumber: "",
        nameOfInsurer: "",
        nameOfInsured: "",
        nameOfInsurance: "",
        expireDate: "",
        fileName: "",
        fileNameWithPath: "",
        typeOfInsurance: "Other Insurance",
        sumOfInsured: "",
        description: "",
      });
    }
  },

  computed: {
    formatDate() {
      return (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "";
      };
    },
  },

  methods: {
    formedData() {
      let output = [];
      for (let insurance of this.addInsurance) {
        output.push({
          insuranceId: insurance.insuranceId,
          policyNumber: insurance.policyNumber,
          nameOfInsurer: insurance.nameOfInsurer,
          nameOfInsured: insurance.nameOfInsured,
          sumOfInsured: insurance.sumOfInsured,
          expireDate: insurance.expireDate,
          fileName: insurance.fileNameWithPath,
          nameOfInsurance: insurance.nameOfInsurance,
          description: insurance.description,
          typeOfInsurance: "Other Insurance",
        });
      }
      return output;
    },

    updateFileName(fileName, index) {
      this.addInsurance[index]["fileName"] = fileName
        ? fileName.split("?")[3]
        : "";
      this.addInsurance[index]["fileNameWithPath"] = fileName;
    },

    addInsuranceData() {
      this.validateInsurance().then((isValid) => {
        if (isValid) {
          this.addInsurance.push({
            insuranceId: 0,
            policyNumber: "",
            nameOfInsurer: "",
            nameOfInsured: "",
            nameOfInsurance: "",
            typeOfInsurance: "Other Insurance",
            sumOfInsured: "",
            expireDate: "",
            fileName: "",
            fileNameWithPath: "",
            description: "",
          });
        }
      });
    },

    validateInsurance() {
      return new Promise((resolve) => {
        let callCount = 0,
          isValidCount = 0;
        for (let eIndex in this.addInsurance) {
          this.$refs["insuranceForm" + eIndex][0]
            .validate()
            .then((validationResponse) => {
              callCount += 1;
              if (validationResponse) {
                isValidCount += 1;
                if (callCount === this.addInsurance.length) {
                  resolve(isValidCount === this.addInsurance.length);
                }
              }
            });
        }
      });
    },

    removeInsuranceData(index, insuranceItem) {
      if (insuranceItem.insuranceId) {
        let vm = this;
        this.$apollo
          .mutate({
            mutation: DELETE_INSURANCE_DETAILS,
            variables: {
              insuranceId: insuranceItem.insuranceId,
            },
            client: "apolloClientC",
          })
          .then(() => {
            let snackbarData = {
              isOpen: true,
              message: "Deleted Successfully",
              type: "success",
            };
            vm.showAlert(snackbarData);
            vm.addInsurance.splice(index, 1);
            if (index === 0 && vm.addInsurance.length === 0) {
              vm.addInsurance.push({
                insuranceId: 0,
                policyNumber: "",
                nameOfInsurer: "",
                nameOfInsured: "",
                nameOfInsurance: "",
                typeOfInsurance: "Other Insurance",
                sumOfInsured: "",
                expireDate: "",
                fileName: "",
                fileNameWithPath: "",
                description: "",
              });
            }
          })
          .catch((deleteErr) => {
            let snackbarData = {
              isOpen: true,
              message:
                deleteErr.graphQLErrors.length !== 0
                  ? deleteErr.graphQLErrors[0].message
                  : "Something went wrong. It could be a possibility due to network connectivity error..",
              type: "warning",
            };
            vm.showAlert(snackbarData);
          });
      } else {
        this.addInsurance.splice(index, 1);
      }
    },

    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
